@font-face {
  font-family: "abelregular";
  src: url("../fonts/abel-regular-webfont.eot");
  src: url("../fonts/abel-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/abel-regular-webfont.woff2") format("woff2"), url("../fonts/abel-regular-webfont.woff") format("woff"), url("../fonts/abel-regular-webfont.ttf") format("truetype"), url("../fonts/abel-regular-webfont.svg#abelregular") format("svg");
  /*
  font-weight: normal;
  font-style: normal;
  */ }

@font-face {
  font-family: "Kefa-Regular";
  src: url("../fonts/Kefa-Regular.eot");
  /* IE9*/
  src: url("../fonts/Kefa-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/abel-regular-webfont.woff2") format("woff2"), url("../fonts/Kefa-Regular.woff") format("woff"), url("../fonts/Kefa-Regular.ttf") format("truetype"), url("../fonts/Kefa-Regular.svg#Kefa-Regular") format("svg");
  /* iOS 4.1- */
  /*
  font-weight: normal;
  font-style: normal;
  */ }

:root {
  --verde-obscuro: #284334;
  --verde-claro: #355942;
  --cafe-claro: #cdb16f;
  --cafe-obscuro: #b18b53;
  --ocre: hsl(37, 63%, 55%);
  --naranja-claro: #da6629;
  --naranja-obscuro: #c2571f;
  --negro: #131313;
  --gris-obscuro: #ababab;
  --gris-claro: #d6d6d6;
  --blanco: #ffffff;
  box-sizing: border-box;
  font-family: "abelregular", sans-serif;
  color: var(--negro);
  font-size: 1em;
  line-height: 150%;
  background-color: var(--blanco); }

*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Kefa-Regular", serif;
  color: var(--naranja-obscuro); }

a {
  color: var(--naranja-claro);
  text-decoration: none; }

section h1,
section h2,
section h3,
section h4,
section h5,
section h6 {
  font-size: 1.4em;
  font-weight: normal;
  margin: 1.3em 0; }

section p {
  margin: 1.4em 0; }

.izquierda {
  text-align: left; }

.derecha {
  text-align: right; }

.centro {
  text-align: center; }

a.derecha {
  float: right; }

.productos hr {
  width: 35px;
  border: 1px solid var(--verde-obscuro); }

.productos h3 {
  font-size: 1.5em;
  font-weight: normal;
  color: var(--verde-obscuro);
  margin: 0.7em 0; }

.resultados div hr {
  border: 1px solid var(--gris-claro); }

.publicaciones {
  background-color: var(--gris-claro); }

.clearfix:after {
  content: "";
  display: table;
  clear: both; }

article.principal footer,
article.interior footer {
  font-size: 0.8em;
  color: var(--gris-obscuro); }
  article.principal footer hr,
  article.interior footer hr {
    border: 1px solid var(--gris-claro); }
  article.principal footer nav .enlaces a,
  article.interior footer nav .enlaces a {
    color: var(--negro);
    font-size: 1.25em; }

aside hr {
  border: 2px solid var(--verde-obscuro);
  width: 60%;
  margin-bottom: 2em; }

article.principal nav .enlaces a,
article.principal nav .toggles a,
article.interior nav .enlaces a,
article.interior nav .toggles a {
  transition: all 0.2s linear; }
  article.principal nav .enlaces a:hover, article.principal nav .enlaces a:active,
  article.principal nav .toggles a:hover,
  article.principal nav .toggles a:active,
  article.interior nav .enlaces a:hover,
  article.interior nav .enlaces a:active,
  article.interior nav .toggles a:hover,
  article.interior nav .toggles a:active {
    color: var(--naranja-obscuro);
    text-decoration: none; }

article.principal nav .enlaces a,
article.principal nav .toggles a,
article.interior nav .enlaces a,
article.interior nav .toggles a {
  font-size: 1.05em;
  color: var(--blanco); }

header.principal hr {
  border: 3px solid var(--verde-obscuro); }

header.principal hgroup h1,
header.principal hgroup h2 {
  color: var(--blanco);
  font-weight: normal; }

header.principal hgroup h1 {
  font-size: 2.55em;
  line-height: 1.2em; }

header.principal hgroup h2 {
  font-size: 1.25em;
  line-height: 1.2em; }

article.interior section h1,
article.interior section h2,
article.interior section h3,
article.interior section h4,
article.interior section h5,
article.interior section h6 {
  margin-top: 3em; }

article.interior nav .enlaces {
  background-color: var(--blanco); }
  article.interior nav .enlaces a {
    color: var(--negro); }

article.interior nav .logos {
  background-color: var(--blanco); }

article.interior header.interior h1 {
  color: var(--verde-obscuro);
  font-size: 2.55em; }

article.interior header.interior hr {
  border: 3px solid var(--verde-obscuro);
  width: 85%; }

article.interior .principal h2 {
  color: var(--verde-obscuro);
  font-size: 2.3em;
  line-height: 1.2em; }

article.interior .principal h3 {
  color: var(--gris-claro);
  font-size: 1.2em;
  line-height: 1em; }

article.interior section.lista section.evento h2 {
  font-size: 1em;
  line-height: 1em;
  color: var(--gris-claro); }

article.interior section.lista section.evento p:nth-child(2) {
  font-size: 1.313em; }

article.interior section.lista section.evento p:nth-child(3) {
  color: var(--gris-claro);
  font-size: 1em; }

a[data-caption] img {
  width: 100%; }

article {
  display: grid;
  grid-template-columns: 1fr minmax(auto, 12.2em) minmax(10px, 55px) minmax(80px, 150px) minmax(10px, 55px) minmax(80px, 150px) minmax(10px, 55px) minmax(80px, 150px) minmax(10px, 55px) minmax(auto, 256px) 1fr;
  grid-template-rows: 23.75em 50px auto 50px auto 50px auto 50px auto auto; }
  article footer {
    text-align: center;
    margin-bottom: 3em; }
    article footer hr {
      margin: 1.5em auto;
      width: 98%; }
    article footer p {
      margin: 0 auto;
      width: 80%; }
    article footer nav {
      margin-top: 4em;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: space-between; }
      article footer nav .enlaces {
        display: flex;
        justify-content: flex-start;
        align-items: center; }
        article footer nav .enlaces img {
          -webkit-filter: opacity(50%);
                  filter: opacity(50%);
          margin: 0 1em; }
          article footer nav .enlaces img:nth-child(1) {
            height: 3em; }
        article footer nav .enlaces a {
          margin: 0 1em;
          border: 0;
          padding: 0; }
      article footer nav .firma {
        display: flex;
        justify-content: flex-end;
        align-items: center; }
        article footer nav .firma img {
          -webkit-filter: opacity(50%);
                  filter: opacity(50%);
          margin: 0 1em; }
          article footer nav .firma img:nth-child(1) {
            height: 3.875em; }
      article footer nav .institucional {
        display: flex;
        justify-content: space-between;
        align-items: center; }
        article footer nav .institucional img {
          margin: 0 1em;
          -webkit-filter: opacity(50%);
                  filter: opacity(50%); }
          article footer nav .institucional img:nth-child(1) {
            -webkit-filter: invert(100%) opacity(50%);
                    filter: invert(100%) opacity(50%);
            height: 4.375em; }
          article footer nav .institucional img:nth-child(2) {
            -webkit-filter: invert(100%) opacity(50%);
                    filter: invert(100%) opacity(50%);
            height: 3.875em; }
          article footer nav .institucional img:nth-child(3) {
            height: 3.875em; }
    article footer > div.enlaces {
      display: flex;
      justify-content: space-evenly; }

article.interior {
  grid-template-rows: auto minmax(7.5em, auto) 50px auto auto 50px auto auto; }
  article.interior .principal h2 {
    margin-top: 0.3em; }
  article.interior .principal h3 {
    margin-left: 10px;
    margin-bottom: 0.3em; }
  article.interior nav.top {
    grid-column-start: 2;
    grid-column-end: 11;
    grid-row-start: 1;
    grid-row-end: 2;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: var(--blanco);
    display: flex;
    justify-content: space-between;
    align-items: center; }
    article.interior nav.top .logos {
      display: flex;
      justify-content: flex-start;
      align-items: center; }
      article.interior nav.top .logos img {
        margin: 0 1em; }
    article.interior nav.top .toggles {
      display: flex;
      justify-content: flex-end;
      align-items: center; }
      article.interior nav.top .toggles a {
        margin: 0 1em; }
    article.interior nav.top .enlaces {
      display: flex;
      justify-content: flex-end;
      align-items: center; }
  article.interior section.principal {
    grid-column-start: 4;
    grid-column-end: 10;
    grid-row-start: 3;
    grid-row-end: 5; }
  article.interior section.lista {
    grid-column-start: 4;
    grid-column-end: 10;
    grid-row-start: 3;
    grid-row-end: 5; }
    article.interior section.lista section.evento {
      margin-top: 2em;
      margin-bottom: 2em; }
      article.interior section.lista section.evento:first-child {
        margin-top: 6em; }
      article.interior section.lista section.evento h2 {
        margin: 0; }
      article.interior section.lista section.evento p:nth-child(2) {
        margin-top: 0.8em;
        margin-bottom: 0; }
      article.interior section.lista section.evento p:nth-child(3) {
        margin-top: 0;
        margin-bottom: 0; }
  article.interior footer {
    grid-column-start: 2;
    grid-column-end: 11;
    grid-row-start: 5;
    grid-row-end: 6; }

article.principal nav.top,
article.interior nav.top {
  z-index: 10; }
  article.principal nav.top .toggles a,
  article.interior nav.top .toggles a {
    margin: 1.5em 1em 0 1em;
    padding-bottom: 1em;
    border-bottom: 5px solid transparent; }
    article.principal nav.top .toggles a:hover,
    article.interior nav.top .toggles a:hover {
      border-bottom-color: var(--naranja-obscuro); }
  article.principal nav.top .enlaces a,
  article.interior nav.top .enlaces a {
    margin: 1.5em 1em 0 1em;
    padding-bottom: 1em;
    border-bottom: 5px solid transparent; }
    article.principal nav.top .enlaces a:hover,
    article.interior nav.top .enlaces a:hover {
      border-bottom-color: var(--naranja-obscuro); }
  article.principal nav.top .logos img:nth-child(1),
  article.interior nav.top .logos img:nth-child(1) {
    height: 4.375em; }
  article.principal nav.top .logos img:nth-child(2),
  article.interior nav.top .logos img:nth-child(2) {
    height: 1.438em; }
  article.principal nav.top .logos img:nth-child(3),
  article.interior nav.top .logos img:nth-child(3) {
    height: 3.813em; }
  article.principal nav.top .logos img:nth-child(4),
  article.interior nav.top .logos img:nth-child(4) {
    height: 3em; }

article.principal nav.top .logos img:nth-child(4) {
  -webkit-filter: invert(100%);
          filter: invert(100%); }

article.principal .wrapper {
  z-index: 1;
  grid-column-start: 2;
  grid-column-end: 11;
  grid-row-start: 1;
  grid-row-end: 2;
  pointer-events: none;
  position: -webkit-sticky;
  position: sticky;
  top: 0; }
  article.principal .wrapper nav {
    pointer-events: all;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-image: url("../img/menu-fondo.png");
    background-size: contain;
    background-size: 1px 120%; }
    article.principal .wrapper nav .logos {
      display: flex;
      justify-content: flex-start;
      align-items: center; }
      article.principal .wrapper nav .logos img {
        margin: 0 1em; }
    article.principal .wrapper nav .toggles {
      display: flex;
      justify-content: flex-end;
      align-items: center; }
    article.principal .wrapper nav .enlaces {
      display: flex;
      justify-content: flex-end;
      align-items: center; }

header.principal {
  grid-column-start: 2;
  grid-column-end: 11;
  grid-row-start: 1;
  grid-row-end: 3;
  background-image: url("../img/banner-overlay.png"), url("../img/banner-mapa.png");
  background-repeat: no-repeat, no-repeat;
  background-size: cover;
  background-position: center, top;
  display: flex;
  align-items: center;
  justify-content: center; }
  header.principal div.titulo {
    text-align: center;
    width: 60%; }
  header.principal hr {
    width: 20%;
    margin: 2em auto; }

div.placeholder {
  background-color: var(--blanco);
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 4; }

section {
  background-color: var(--blanco); }
  section.principal {
    grid-column-start: 4;
    grid-column-end: 9;
    grid-row-start: 2;
    grid-row-end: 4; }
  section.resultados {
    grid-column-start: 4;
    grid-column-end: 9;
    grid-row-start: 7;
    grid-row-end: 8; }
    section.resultados .productos {
      display: grid;
      grid-template-columns: minmax(80px, 150px) minmax(10px, 55px) minmax(80px, 150px) minmax(10px, 55px) minmax(80px, 150px);
      grid-template-rows: auto auto auto;
      grid-row-gap: 50px; }
      section.resultados .productos #resultado_01 {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2; }
      section.resultados .productos #resultado_02 {
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 2; }
      section.resultados .productos #resultado_03 {
        grid-column-start: 5;
        grid-column-end: 6;
        grid-row-start: 1;
        grid-row-end: 2; }
      section.resultados .productos #resultado_04 {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 3; }
      section.resultados .productos #resultado_05 {
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 2;
        grid-row-end: 3; }
    section.resultados h2 {
      float: left;
      margin-top: 0; }
    section.resultados div hr {
      margin-top: 0.7em;
      float: right;
      width: 40%; }
  section.publicaciones {
    grid-column-start: 2;
    grid-column-end: 11;
    grid-row-start: 9;
    grid-row-end: 10;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around; }
    section.publicaciones .publicacion {
      width: 30%;
      background-color: unset;
      margin-top: 1em;
      margin-bottom: 1em;
      position: relative; }
      section.publicaciones .publicacion a {
        position: absolute;
        bottom: 0;
        right: 0; }

aside.imagen {
  grid-column-start: 2;
  grid-column-end: 9;
  grid-row-start: 5;
  grid-row-end: 6; }
  aside.imagen img {
    width: 100%; }

aside.texto {
  grid-column-start: 10;
  grid-column-end: 11;
  grid-row-start: 5;
  grid-row-end: 6; }

article.principal footer {
  grid-column-start: 2;
  grid-column-end: 11;
  grid-row-start: 10;
  grid-row-end: 11; }

header.interior {
  grid-column-start: 4;
  grid-column-end: 9;
  grid-row-start: 2;
  grid-row-end: 4;
  z-index: 1;
  display: flex;
  align-content: center; }
  header.interior hr {
    margin-bottom: 1.2em; }
  header.interior div {
    line-height: 2.5em;
    min-height: 10em; }
    header.interior div h1 {
      margin-bottom: 0.8em;
      margin-top: 0.8em; }
    header.interior div div {
      margin-top: 2em;
      margin-bottom: 2em; }

aside.encabezado {
  grid-column-start: 7;
  grid-column-end: 11;
  grid-row-start: 2;
  grid-row-end: 4;
  content: "";
  background-image: url("../img/interior-mapa.png");
  background-repeat: no-repeat, no-repeat;
  background-size: cover; }

@charset "UTF-8";
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

/*
Algo así para article.principal .wrapper nav .enlaces: 
flex-direction: column;
position: absolute;
right: 0;
top: 0;
background-color: black;
*/
@media (max-width: 1199px) {
  article.principal .wrapper nav.top, article.interior nav.top {
    font-size: 0.9em; }
    article.principal .wrapper nav.top .logos img:nth-child(2), article.interior nav.top .logos img:nth-child(2) {
      display: none; } }

@media (max-width: 991px) {
  article.principal .wrapper nav.top, article.interior nav.top {
    font-size: 0.8em; }
    article.principal .wrapper nav.top .logos img:nth-child(3), article.interior nav.top .logos img:nth-child(3) {
      display: none; } }

@media (max-width: 767px) {
  :root {
    font-size: 1.2em; }
  article.interior nav .enlaces a {
    color: var(--blanco); }
  article.interior nav .toggles a {
    color: var(--negro); }
  article.principal .wrapper nav.top, article.interior nav.top {
    font-size: 1em; }
    article.principal .wrapper nav.top .enlaces, article.interior nav.top .enlaces {
      right: -12.5em;
      transition: right 0.3s ease; }
      article.principal .wrapper nav.top .enlaces a, article.interior nav.top .enlaces a {
        margin: 0;
        padding: 1em; }
      article.principal .wrapper nav.top .enlaces:target, article.principal .wrapper nav.top .enlaces[aria-expanded="true"], article.interior nav.top .enlaces:target, article.interior nav.top .enlaces[aria-expanded="true"] {
        right: 0; }
  article nav.top .enlaces {
    flex-direction: column;
    position: fixed;
    right: 0;
    top: 0;
    background-color: var(--negro);
    text-align: center;
    width: 12.5em; }
    article nav.top .enlaces a {
      width: 100%; }
      article nav.top .enlaces a:first-child {
        text-align: right; }
  article nav.top .logos img:nth-child(1) {
    display: none; }
  article nav.top .logos img:nth-child(2) {
    display: none; }
  article nav.top .logos img:nth-child(3) {
    display: none; }
  article.principal nav.top .logos img:nth-child(4) {
    -webkit-filter: invert(100%);
            filter: invert(100%); }
  article.principal aside.imagen {
    display: none; }
  article.principal aside.texto {
    display: none; }
  header.principal hr {
    width: 50%; }
  header.principal .titulo img {
    display: none; }
  section.principal {
    grid-column-start: 3;
    grid-column-end: 10;
    grid-row-start: 2;
    grid-row-end: 4; }
  section.resultados div hr {
    width: 10%; }
  section.publicaciones .publicacion {
    width: 80%; }
    section.publicaciones .publicacion:nth-child(2) {
      display: none; }
    section.publicaciones .publicacion:nth-child(3) {
      display: none; }
  article.interior section.principal {
    grid-column-start: 3;
    grid-column-end: 10;
    grid-row-start: 3;
    grid-row-end: 5; }
  article.interior section.lista {
    grid-column-start: 3;
    grid-column-end: 10;
    grid-row-start: 3;
    grid-row-end: 5; }
  header.interior {
    grid-column-start: 3;
    grid-column-end: 9;
    grid-row-start: 2;
    grid-row-end: 4; }
  article footer nav {
    display: block; }
    article footer nav div.enlaces, article footer nav div.firma {
      display: none; } }

@media (min-width: 768px) {
  article.principal .wrapper nav.top .logos img:nth-child(4), article.interior nav.top .logos img:nth-child(4) {
    display: none; }
  article.principal .wrapper nav.top .toggles,
  article.principal .wrapper nav.top .menu-close, article.interior nav.top .toggles,
  article.interior nav.top .menu-close {
    display: none; }
  article footer nav div.institucional {
    display: none; } }

/*# sourceMappingURL=estilo.css.map */
